import { render, staticRenderFns } from "./selectAreaPoint.vue?vue&type=template&id=06a2cace&scoped=true"
import script from "./selectAreaPoint.vue?vue&type=script&lang=js"
export * from "./selectAreaPoint.vue?vue&type=script&lang=js"
import style0 from "./selectAreaPoint.vue?vue&type=style&index=0&id=06a2cace&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06a2cace",
  null
  
)

export default component.exports