<!--集体点播-->
<template>
  <div>
    <van-nav-bar title="放映员点播" left-arrow @click-left="goBack" />
    <van-cell-group>
      <van-cell title="期望放映地址" required>
        <template #label>
          <van-field
            v-model="userPoint.address"
            readonly
            class="box-input"
            placeholder="请填写期望放映地址"
            @click="selectPoint"
            right-icon="location"
          />
        </template>
      </van-cell>
      <van-cell title="期望放映日期" required>
        <template #label>
          <van-field
            readonly
            class="box-input"
            placeholder="请填写放映日期"
            center
            right-icon="calendar-o"
            @click="dateHandle"
          >
            <template #input>
              {{
                moment(subParam.expectPlayTime).format('YYYY年MM月DD日') +
                '    ' +
                TimeToOption(subParam.showTime)
              }}
            </template>
          </van-field>
        </template>
      </van-cell>
      <van-cell title="预计放映时间" required>
        <template #label>
          <van-field
            readonly
            class="box-input"
            placeholder="请填写放映时间"
            @click="showTimePicker = true"
            center
            right-icon="clock-o"
            v-model="subParam.showTime"
          >
          </van-field>
        </template>
      </van-cell>
      <van-cell title="点播影片" required>
        <template #label>
          <div class="filmBtn">
            <van-button
              icon="tv-o"
              type="info"
              round
              size="small"
              @click="showFilm = true"
              >选择电影
            </van-button>
            <span
              >已选中 <i class="count">{{ filmList.length }}</i
              >部</span
            >
          </div>
        </template>
      </van-cell>
      <van-cell v-for="(film, index) in filmList" :key="index">
        <template #default>
          <div class="film">
            <div class="film-mark">{{ index + 1 }}</div>
            <div class="film-title">{{ film.title }}</div>
            <div class="film-del">
              <van-icon name="delete" @click="removeFilm(film)" size="20" />
              <van-icon
                name="upgrade"
                color="#3d8bff"
                size="20"
                v-if="index !== 0"
                @click="moveUpFilm(index)"
              />
              <van-icon
                name="upgrade"
                color="#3d8bff"
                style="transform: rotate(180deg)"
                size="20"
                v-if="index !== filmList.length - 1"
                @click="moveDownFilm(index)"
              />
            </div>
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <div class="box-button">
      <van-button
        round
        type="info"
        size="large"
        style="width: 90%"
        @click="submit"
        color="#3d8bff"
        >提交
      </van-button>
    </div>
    <film-picker
      :show="showFilm"
      @close="showFilm = false"
      @onFinished="selectFilm"
      :showPointId="userPoint.id"
    />
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker
        type="time"
        title="选择时间"
        :min-hour="7"
        :max-hour="22"
        @cancel="showTimePicker = false"
        @confirm="confirmTime"
      />
    </van-popup>
    <select-area-point
      ref="selectAreaPoint"
      :show="show.addressShow"
      @close="show.addressShow = false"
      @onFinish="onFinishPoint"
    />
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import { api } from '@/api'
import filmPicker from '@comp/filmPicker/index.vue'
import selectAreaPoint from './selectAreaPoint.vue'

export default {
  name: 'groupOnDemand',
  components: {
    filmPicker,
    selectAreaPoint,
  },
  data() {
    return {
      filmList: [],
      showFilm: false,
      showTimePicker: false,
      showDateType: 0,
      userPoint: {},
      userInfo: null,
      minDate: new Date(),
      address: '',
      dataList: {
        movieList: [],
      },
      addressOptions: [],
      show: {
        date: false,
        addressShow: false,
      },
      subParam: {
        expectPlayTime: '',
        showTime: '',
        alternativePlayTime: {},
        expectPlayPlace: '',
        filmIds: [],
      },
    }
  },
  mounted() {
    // this.userPoint = this.$store.state.userPoint
    this.userInfo = this.$store.state.userInfo
    this.init()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    dateHandle() {
      Dialog.confirm({
        title: '选择放映日期',
        message: '若选择的日期有放映，则会跳转到上级页面，请确认是否选择',
        confirmButtonText: '确认',
        confirmButtonColor: '#3d8bff',
        cancelButtonText: '取消',
      }).then(() => {
        this.goBack()
      })
    },
    showTime() {
      this.showTimePicker = true
    },
    selectPoint() {
      // this.$router.replace({
      //   path: '/ruralFilm/selectPoint',
      //   query: {
      //     form: this.$route.path,
      //     back: true,
      //   },
      // })
      this.$refs.selectAreaPoint.open()
    },
    init() {
      this.subParam.expectPlayTime = this.getUrlParam('date')
      console.log(this.getUrlParam('showPoint'));
      if (this.getUrlParam('showPoint')) {
        this.userPoint = JSON.parse(this.getUrlParam('showPoint'))
      }
      const minDate = this.moment().weekday(7).format('YYYY-MM-DD')
      this.minDate = new Date(minDate)
    },
    getUrlParam(name) {
      return this.$route.query[name] || ''
    },
    selectFilm(item) {
      const hasFilm = this.filmList.filter((film) => film.id === item.id)
      if (hasFilm.length > 0) {
        Toast.fail('该影片已添加')
        return
      }
      if (this.filmList.indexOf(item) == -1) {
        this.filmList.push(item)
        Toast.success('添加成功')
      } else {
        Toast('该影片已添加')
      }
    },
    confirmTime(time) {
      this.showTimePicker = false
      this.subParam.showTime = time
    },
    // 删除影片
    removeFilm(item) {
      this.filmList.splice(this.filmList.indexOf(item), 1)
    },
    // 上移影片
    moveUpFilm(index) {
      if (index === 0) return
      let filmList = this.filmList
      let temp = filmList[index]
      filmList[index] = filmList[index - 1]
      filmList[index - 1] = temp
      this.filmList = [...filmList]
    },
    // 下移影片
    moveDownFilm(index) {
      if (index === this.filmList.length - 1) return
      let filmList = this.filmList
      let temp = filmList[index]
      filmList[index] = filmList[index + 1]
      filmList[index + 1] = temp
      this.filmList = [...filmList]
    },
    // 选择备选放映时间
    selectAddress() {
      this.show.addressShow = true
    },
    // 选择备选放映时间
    onFinish(selectedOptions) {
      this.show.addressShow = false
      this.address = selectedOptions.map((option) => option.text).join('')
      this.subParam.expectPlayPlace = this.address
    },
    onFinishPoint(point){
      console.log('point', point);
      
      this.userPoint = point
    },

    // 根据时间返回上午 / 下午 / 晚上

    TimeToOption(str) {
      console.log({ str })

      if (!str) return ''
      const time = str.split(':')
      const hour = parseInt(time[0])
      if (hour >= 5 && hour < 12) {
        return '上午'
      } else if (hour >= 12 && hour < 18) {
        return '下午'
      } else return '晚上'
    },
    // 根据12：00 格式转 playPeriod
    TimeToEn(str) {
      if (!str) return ''
      const time = str.split(':')
      const hour = parseInt(time[0])
      if (hour >= 8 && hour < 12) {
        return 'morning'
      } else if (hour >= 12 && hour < 18) {
        return 'afternoon'
      } else return 'evening'
    },

    submit() {
      if (this.subParam.expectPlayPlace == '') {
        this.subParam.expectPlayPlace = this.address
      }
      if (!this.subParam.showTime) {
        Toast.fail('请选择预计放映时间')
        return
      }
      if (!this.filmList.length) {
        Toast.fail('请选择影片')
        return
      }

      const param = {
        filmIds: this.filmList.map((item) => item.id),
        playStartDate: this.subParam.expectPlayTime,
        playStartTime: this.subParam.showTime + ':00',
        playPeriod: this.TimeToEn(this.subParam.showTime),
        showPointId: this.userPoint.id,
      }
      console.log('param------------------', param)
      Dialog.confirm({
        title: '确认提交',
        message: '请确认信息无误后再提交',
        confirmButtonText: '确认',
        cancelButtonText: '取消',
      }).then(() => {
        api
          .submitScreeningClick(param)
          .then((res) => {
            const { success, message } = res
            if (success) {
              // Toast('提交成功')
              // setTimeout(() => {
              //   this.$router.history.go(-1)
              // }, 3000)
              Dialog.alert({
                title: '提交成功',
                message: '请等待上级审核',
                confirmButtonText: '确定',
              }).then(() => {
                this.$router.history.go(-1)
              })
            }else {
              Toast.fail(message)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
  },
  computed: {
    hopePlayTime() {
      const date = this.subParam.expectPlayTime
      if (!date.Date) return ''
      return `${this.moment(date.Date).format('MM月DD日')} ${date.Time}`
    },
    alternativePlayTime() {
      const date = this.subParam.alternativePlayTime
      if (!date.Date) return ''
      return `${this.moment(date.Date).format('MM月DD日')} ${date.Time}`
    },
  },
}
</script>

<style scoped lang="less">
::v-deep .van-cell {
  line-height: normal;
  background-color: #f6f7f9;
}

.box-button {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 10px;
  left: 0;
}

.box {
  padding: 10px 10px;
  min-height: 80px;
  margin-bottom: 100px;

  &-input {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e8e9ec;
    min-height: 56px;
    padding: 20px;
  }
}

.desc-box {
  font-size: 14px;
  padding: 10px;
  color: darkgray;
}

.filmBtn {
  width: 45vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .count {
    color: @color-primary;
    font-style: normal;
    font-weight: bold;
    padding: 0 5px;
  }
}

.film {
  display: flex;
  align-items: center;

  &-mark {
    display: flex;
    justify-content: center;
    align-items: center;
    color: @color-primary;
    margin: 12px;
    position: relative;

    &:before {
      content: '';
      border: 1px solid @color-primary;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      position: absolute;
    }
  }

  &-title {
    padding: 12px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #e8e9ec;
    width: 70vw;
    color: #686b73;
  }

  &-del {
    color: @color-error;
    margin-left: 12px;
    width: 20vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>
